import React from 'react';
import { motion } from 'framer-motion';
import { styled } from '@swibeco/shared';
import christmasLoading from '../assets/gifs/christmas-loading.gif';
import { useSelector } from 'react-redux';
import { selectors as coreSelectors } from '@swibeco/core';

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Loader = () => {
  const christmasFlag = useSelector(coreSelectors.getChristmasFlag);
  if (christmasFlag) {
    return (
      <Wrapper data-testid="app-loader">
        <img src={christmasLoading} alt="loading" />
      </Wrapper>
    );
  }
  return (
    <Wrapper data-testid="app-loader">
      <motion.img
        src="/static/img/placeholders/swibeco_favicon.png"
        animate={{ rotate: 360 }}
        transition={{
          repeat: Infinity,
          repeatType: 'loop',
          stiffness: 100,
          duration: 1.5,
          repeatDelay: 0.2,
        }}
      />
    </Wrapper>
  );
};

export default Loader;
