import {
  AnalyticsEvents,
  BaseDeal,
  ColorVariants,
  DealQuantityFormErrorType,
  DealQuantityFormType,
  ErrorResponse,
  MappedProduct,
  MappedProductVariant,
  ProductPageDeal,
  ProductVariant,
} from '@swibeco/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { actions, usePlatform } from '@swibeco/core';
import { ecommerceApi } from '@swibeco/ecommerce';
import TagManager from 'react-gtm-module';
import {
  createDataLayerDealObject,
  createDataLayerProductVariantObject,
  generatePageName,
} from '@swibeco/shared';
import { useLocation } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { Toast } from '@swibeco/ui';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const useAddToBasket = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const pageName = generatePageName(pathname);
  const environment = usePlatform(window);
  const toast = useToast();
  const { t } = useTranslation();

  const addItemToBasket = useMutation({
    mutationFn: (data: DealQuantityFormType) =>
      ecommerceApi
        .addItemToBasket(data.deal.id, data.quantity)
        .then((response) => ({
          quantity: data.quantity,
          deal: data.deal,
          response,
        })),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['basketItemsCount'],
      });
      queryClient.invalidateQueries({
        queryKey: ['basket'],
      });
    },
    onError: (error: ErrorResponse) => {
      const { response } = error;
      if (response?.status === 422) {
        const codes = response.data.errors.map(
          (error: DealQuantityFormErrorType) => error.code
        );
        if (codes.includes('max_per_user_days_limit')) {
          const error = response.data.errors.find(
            (error: DealQuantityFormErrorType) =>
              error.code === 'max_per_user_days_limit'
          );
          const maxPerUser = error?.max_per_user;
          const maxPerUserDays = error?.max_per_user_days;
          toast({
            id: 'max-per-user-days-limit',
            position: 'top-right',
            isClosable: true,
            render: ({ onClose }) => (
              <Toast
                variant={ColorVariants.Danger}
                onCloseButtonClick={onClose}
                legacy={false}
              >
                <Trans
                  i18nKey="core.ecommerce.universe.max_per_user_days_limit.error"
                  values={{
                    maxPerUser,
                    maxPerUserDays,
                  }}
                />
              </Toast>
            ),
          });
        }
        if (codes.includes('max_per_user_limit')) {
          toast({
            id: 'max-per-user-limit',
            position: 'top-right',
            isClosable: true,
            render: ({ onClose }) => (
              <Toast
                variant={ColorVariants.Danger}
                onCloseButtonClick={onClose}
                legacy={false}
              >
                {t('core.ecommerce.universe.max_per_user_limit.error')}
              </Toast>
            ),
          });
        }
        if (codes.includes('deal_quantity_reach')) {
          toast({
            id: 'out-of-stock',
            position: 'top-right',
            isClosable: true,
            render: ({ onClose }) => (
              <Toast
                variant={ColorVariants.Danger}
                onCloseButtonClick={onClose}
                legacy={false}
              >
                {t('core.ecommerce.universe.out_of_stock.error')}
              </Toast>
            ),
          });
        }
      }
    },
    retry: (failureCount, error) => {
      if (error.response.status === 422 && failureCount < 3) {
        return true;
      }
      return false;
    },
    retryDelay: 1000,
  });

  const addItem = async (
    deal: BaseDeal | ProductPageDeal | ProductVariant,
    quantity: number
  ) => {
    try {
      const res = await addItemToBasket.mutateAsync({ deal, quantity });
      TagManager.dataLayer(
        createDataLayerDealObject(
          AnalyticsEvents.ADD_TO_CART,
          environment,
          [deal],
          quantity,
          pageName
        )
      );
      dispatch(actions.showOnAddItem(quantity));
      setTimeout(() => {
        dispatch(actions.hideOnAddItem());
      }, 3000);
      return res;
    } catch (e) {
      return false;
    }
  };
  const addProduct = async (
    product: MappedProduct | MappedProductVariant,
    quantity: number,
    multipleAdd?: boolean
  ) => {
    try {
      const res = await addItemToBasket.mutateAsync({
        deal: product,
        quantity,
      });
      TagManager.dataLayer(
        createDataLayerProductVariantObject(
          AnalyticsEvents.ADD_TO_CART,
          environment,
          [product],
          quantity,
          pageName
        )
      );
      if (!multipleAdd) {
        dispatch(actions.showOnAddItem(quantity));
        setTimeout(() => {
          dispatch(actions.hideOnAddItem());
        }, 3000);
      }
      return res;
    } catch (error) {
      let errorMessage = `Error when adding product ${product.slug}: `;
      if (error.response && error.response.status === 422) {
        errorMessage += `Specific error 422 occurred. ${error.message}`;
      } else {
        errorMessage += `An unexpected error occurred. ${error.message}`;
      }
      throw new Error(errorMessage);
    }
  };

  return {
    addItem,
    isLoading: addItemToBasket.isPending,
    addProduct,
  };
};

export default useAddToBasket;
