import React, { forwardRef } from 'react';
import { Box, Flex, Grid } from '@chakra-ui/react';
import { SubTitle } from '@swibeco/ui';
import { useTranslation } from 'react-i18next';
import { useSubscriptionDeals } from '@swibeco/ecommerce';
import { ProductVariant } from '@swibeco/types';
import { PlusPlanCard } from '../PlusPlanCard';

const PlusPlans = forwardRef<HTMLDivElement>((_props, ref) => {
  const { t } = useTranslation();
  const { subscriptions } = useSubscriptionDeals();
  if (!subscriptions) {
    return null;
  }

  return (
    <Flex
      ref={ref}
      as="section"
      w="100%"
      m="0 auto"
      textAlign="center"
      scrollMarginTop="110px"
      flexDir="column"
      gap="24px"
    >
      <Box mb="16px">
        <SubTitle>{t('core.ecommerce.plus.plans.title')}</SubTitle>
      </Box>

      <Grid
        justifyContent="space-between"
        alignItems="center"
        gap={{ base: '16px', lg: '40px' }}
        templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
        templateRows={{ base: 'repeat(3, 1fr)', md: 'unset' }}
      >
        {subscriptions.map((sub: ProductVariant, index: number) => (
          <PlusPlanCard
            key={sub.id}
            isPopularOffer={index === 1}
            subscription={sub}
          />
        ))}
      </Grid>
    </Flex>
  );
});

export default PlusPlans;
