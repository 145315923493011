import DOMPurify from 'dompurify';
import React from 'react';
import { CloseIcon, Legals, Text } from '@swibeco/ui';
import { DealSharedCodeInfo } from '@swibeco/types';
import {
  AbsoluteCenter,
  Box,
  Flex,
  IconButton,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import VoucherModalCodeContent from '../VoucherModalContent/VoucherModalCodeContent';
import fallbackDealMedia from '../../../assets/images/fallback_deal_media.png';

export type VoucherModalProps = {
  isOpen?: boolean;
  toggle: () => void;
  voucherTitle?: string;
  voucherBrandImage?: string;
  voucherPrice?: number;
  dealImage?: string;
  voucherInfo?: DealSharedCodeInfo;
  isLoading?: boolean;
};

const VoucherModal = ({
  isOpen,
  toggle,
  dealImage,
  voucherTitle,
  voucherBrandImage,
  voucherInfo,
  isLoading,
}: VoucherModalProps) => {
  const { t } = useTranslation();
  const sanitizedContent = DOMPurify.sanitize(
    voucherInfo?.deliveryDescription ?? ''
  );
  return (
    <Modal isOpen={isOpen || false} onClose={toggle} isCentered>
      <ModalOverlay />
      <ModalContent
        minWidth={{
          base: '90%',
          lg: '1080px',
        }}
        maxWidth={{
          base: '90%',
          lg: '70%',
        }}
        height={{
          base: '75%',
          lg: '60%',
        }}
      >
        {isLoading ? (
          <AbsoluteCenter>
            <Text>{t('core.ecommerce.loading')}</Text>
          </AbsoluteCenter>
        ) : (
          <>
            <IconButton
              position="absolute"
              top="32px"
              right="24px"
              zIndex={99}
              aria-label="close"
              onClick={toggle}
              borderRadius={99}
              variant="ghost"
              sx={{
                span: {
                  marginLeft: 0,
                },
              }}
            >
              <CloseIcon />
            </IconButton>
            <Flex height="100%">
              <Box className="d-none d-lg-block col-4 p-0">
                <Image
                  objectFit="cover"
                  borderRadius="0.3rem 0 0 0.3rem"
                  height="100%"
                  src={dealImage ?? fallbackDealMedia}
                />
              </Box>
              <Flex
                flexDir="column"
                p={4}
                w="100%"
                alignItems="center"
                justifyContent="space-between"
                textAlign="center"
                overflowY="auto"
              >
                <Flex flexDir="column" alignItems="center">
                  <Image
                    maxW="200px"
                    maxH="80px"
                    padding="8px"
                    borderRadius="5px"
                    bgColor="default.main"
                    src={voucherBrandImage}
                  />
                  <Text className="mt-2 mb-0" important>
                    {voucherTitle}
                  </Text>
                </Flex>
                <VoucherModalCodeContent
                  code={voucherInfo?.code}
                  url={voucherInfo?.externalLink}
                />
                <Flex textAlign="start" alignSelf="start" mt="40px">
                  <Legals>
                    <span
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: sanitizedContent,
                      }}
                    />
                  </Legals>
                </Flex>
              </Flex>
            </Flex>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default VoucherModal;
