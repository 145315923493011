import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { selectors as coreSelectors } from '@swibeco/core';
import { syliusApi } from '../api';

export const useSubscriptionDeals = () => {
  const locale = useSelector(coreSelectors.getLocale);

  const { data, ...rest } = useQuery({
    queryKey: ['subscriptions', locale],
    queryFn: () => syliusApi.getTrialSubscriptions(locale),
  });

  return { subscriptions: data, ...rest };
};
