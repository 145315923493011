import { API, camelcaseKeys } from '@swibeco/shared';
import {
  BaseDeal,
  Brand,
  Category,
  AvailableSearchParamValues,
  PaginationResponse,
} from '@swibeco/types';
import { UniverseParams } from '../hooks/useUniverseParams';
import { mapSortToOrderParams } from '../utils/mapSortToOrderParams';

export default {
  getUniverseDeals: async (
    locale: string,
    universeParams: UniverseParams,
    filterParams: Partial<AvailableSearchParamValues>,
    listType = 'all'
  ): Promise<BaseDeal[]> => {
    let params = {};
    if (listType !== 'filter') {
      params = {
        filter_brands:
          filterParams.brands &&
          filterParams.brands.map((brand) => brand.value),
        filter_offer_types:
          filterParams.offerType &&
          filterParams.offerType.map((offerType) => offerType.value),
        filter_swipoints: filterParams.buyableWithSwipoints,
      };
      if (filterParams.reimbursable) {
        params = {
          ...params,
          filter_cashback: 1,
        };
      }
      if (filterParams.offersFrom?.length === 1) {
        params = {
          ...params,
          filter_offer_from: filterParams.offersFrom[0].value,
        };
      }
    }

    if (filterParams.sort) {
      params = {
        ...params,
        ...mapSortToOrderParams(filterParams.sort),
      };
    }
    const { data: response } = await API.get('/deals/search', {
      params: {
        locale,
        ...universeParams.apiValue,
        ...params,
        'list-type':
          // eslint-disable-next-line no-nested-ternary
          universeParams.apiValue.value === 'flash-deals'
            ? 'only-flash'
            : listType === 'filter'
            ? 'all'
            : listType,
        page_size: listType === 'all' || listType === 'filter' ? 1000 : 200,
      },
    });
    return camelcaseKeys(response.data);
  },
  getCategories: async (locale: string): Promise<Category[]> => {
    const response = await API.get('/categories/', {
      params: { locale },
    });
    return camelcaseKeys(response.data, { deep: true });
  },
  getCategoriesWithDeals: async (locale: string): Promise<Category[]> => {
    const response = await API.get('/categories/with-deals', {
      params: { locale },
    });
    return camelcaseKeys(response.data, { deep: true });
  },
  getCategory: async (slug: string, locale: string): Promise<Category> => {
    const response = await API.get(`/categories/${slug}`, {
      params: { locale },
    });
    return camelcaseKeys(response.data, { deep: true });
  },
  getBrands: async (
    locale: string,
    slug: string
  ): Promise<PaginationResponse<Brand>> => {
    const response = await API.get(`/categories/${slug}/brands`, {
      params: { locale },
    });
    return camelcaseKeys(response.data, { deep: true });
  },
  getBrand: async (locale: string, slug: string): Promise<Brand> => {
    const response = await API.get(`/brands/${slug}`, {
      params: { locale },
    });
    return camelcaseKeys(response.data, { deep: true });
  },
  getPopularDeals: async (
    locale: string,
    slug?: string
  ): Promise<BaseDeal[]> => {
    const response = await API.get('/deals', {
      params: {
        locale,
        criteria: 'popular-offers',
        ...(slug && { category: slug }),
        limit: 200,
      },
    });
    return camelcaseKeys(response.data, { deep: true });
  },
};
