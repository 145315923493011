import { BasketItemRaw } from '@swibeco/types';
import { isPast } from 'date-fns';

export const isBasketItemOutOfStock = (basketItem: BasketItemRaw) =>
  basketItem.variant.tracked &&
  basketItem.variant.onHand - basketItem.variant.onHold <= 0;

export const isBasketItemPartialOutOfStock = (basketItem: BasketItemRaw) =>
  basketItem.variant.tracked &&
  basketItem.variant.onHand - basketItem.variant.onHold <= 0;

export const isBasketItemExpired = (deal: BasketItemRaw) =>
  deal?.variant.isFlash &&
  deal?.variant.endDatetime &&
  isPast(new Date(deal.variant.endDatetime));

export const isBasketItemReachedMaxPerUser = (basketItem: BasketItemRaw) =>
  basketItem.variant.maxPerUserEnabled && basketItem.variant.maxPerUser >= 0;
