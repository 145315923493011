import { camelcaseKeys, LegacyAPI } from '@swibeco/shared';
import {
  PaginationResponse,
  SwipointsHistoryItem,
  SwipointsBalanceWithExpiry,
} from '@swibeco/types';

export default {
  getLegacySwipointsHistory: async (
    page: string
  ): Promise<PaginationResponse<SwipointsHistoryItem>> => {
    const response = await LegacyAPI.get('/user/swipoint-history', {
      params: { page, order_by: 'createdAt', order_direction: 'desc' },
    });
    return camelcaseKeys(response.data, { deep: true });
  },
  getLegacySwipointsBalanceWithExpirationDate: async (): Promise<
    SwipointsBalanceWithExpiry[]
  > => {
    const response = await LegacyAPI.get(
      '/user/swipoint-balance-with-expiration-date'
    );
    return camelcaseKeys(response.data, { deep: true });
  },
};
