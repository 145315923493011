import { produce } from 'immer';
import { CheckoutStore, CheckoutTypes } from './checkoutTypes';

const checkoutReducer = produce((state: CheckoutStore, action) => {
  switch (action.type) {
    case CheckoutTypes.SET_ADDRESSES:
      state.user.addresses.billingAddress = action.payload.billingAddress
        ? {
            firstName: action.payload.billingAddress.firstName,
            lastName: action.payload.billingAddress.lastName,
            street: action.payload.billingAddress.street,
            complement: action.payload.billingAddress.complement,
            postalCode: action.payload.billingAddress.postalCode,
            city: action.payload.billingAddress.city,
            country: action.payload.billingAddress.country,
            phonePrefix: action.payload.billingAddress.phonePrefix,
            phone: action.payload.billingAddress.phone,
          }
        : null;
      state.user.addresses.deliveryAddress = action.payload.deliveryAddress
        ? {
            firstName: action.payload.deliveryAddress.firstName,
            lastName: action.payload.deliveryAddress.lastName,
            street: action.payload.deliveryAddress.street,
            complement: action.payload.deliveryAddress.complement,
            postalCode: action.payload.deliveryAddress.postalCode,
            city: action.payload.deliveryAddress.city,
            country: action.payload.deliveryAddress.country,
            phonePrefix: action.payload.deliveryAddress.phonePrefix,
            phone: action.payload.deliveryAddress.phone,
          }
        : null;
      break;
    case CheckoutTypes.SET_BASKET:
      state.basket.items = action.payload.items;
      state.basket.totalProducts = action.payload.totalProducts;
      state.basket.totalDelivery = action.payload.totalDelivery;
      state.basket.totalAmount = action.payload.totalAmount;
      state.basket.finalAmountToPay = action.payload.finalAmountToPay;
      state.basket.hasPhysical = action.payload.hasPhysical;
      state.basket.hasDigital = action.payload.hasDigital;
      state.basket.hasSubscription = action.payload.hasSubscription;
      state.basket.hasPhysical = action.payload.hasPhysical;
      state.basket.totalSavings = action.payload.totalSavings;
      state.basket.totalCashback = action.payload.totalCashback;
      break;
    case CheckoutTypes.SET_ALLOCATED_SWIPOINTS:
      state.user.allocatedSwipoints = action.payload;
      state.basket.finalAmountToPay = state.basket.totalAmount - action.payload;
      break;
    case CheckoutTypes.SET_USE_DELIVERY_ADDRESS_FOR_BILLING:
      state.user.useDeliveryAddressForBilling = action.payload;
      break;
    case CheckoutTypes.SET_BILLING_ADDRESS:
      state.user.addresses.billingAddress = {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        street: action.payload.street,
        complement: action.payload.complement,
        postalCode: action.payload.postalCode,
        city: action.payload.city,
        country: action.payload.country,
        phonePrefix: action.payload.phonePrefix,
        phone: action.payload.phone,
      };
      break;
    case CheckoutTypes.SET_DELIVERY_ADDRESS:
      state.user.addresses.deliveryAddress = {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        street: action.payload.street,
        complement: action.payload.complement,
        postalCode: action.payload.postalCode,
        city: action.payload.city,
        country: action.payload.country,
        phonePrefix: action.payload.phonePrefix,
        phone: action.payload.phone,
      };
      break;
    case CheckoutTypes.SET_ERRORS:
      state.errors = {
        ...state.errors,
        ...action.payload,
      };
      break;
    default:
      break;
  }
});

export default checkoutReducer;
