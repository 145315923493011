import { BasketItemRaw, ProductVariant } from '@swibeco/types';

export const formatSubscriptionData = (
  t: any,
  subscription: ProductVariant | BasketItemRaw
) => {
  let finalPrice = 1;
  let subscriptionDuration;
  if ('price' in subscription) {
    finalPrice = subscription.price;
    subscriptionDuration = subscription.subscriptionDuration;
  }
  if ('total' in subscription) {
    finalPrice = subscription.total;
    subscriptionDuration = subscription.variant.subscriptionDuration;
  }
  const isFreeTrial = finalPrice === 0;
  let durationFormatted: string;
  let perDurationFormatted: string;
  let pricePerMonth: number;
  const years = subscriptionDuration! / 365;
  const months = subscriptionDuration! / 30;
  const monthsFloored = Math.floor(months);
  const yearsFloored = Math.floor(years);

  if (years >= 1) {
    durationFormatted = `${yearsFloored} ${
      yearsFloored > 1
        ? t('core.ecommerce.plus.plans.years')
        : t('core.ecommerce.plus.plans.year')
    }`;
    perDurationFormatted =
      yearsFloored > 1
        ? t('core.ecommerce.plus.plans.for_years', { years })
        : t('core.ecommerce.plus.plans.year');
  } else if (months >= 1) {
    durationFormatted = `${monthsFloored} ${
      monthsFloored > 1
        ? t('core.ecommerce.plus.plans.months')
        : t('core.ecommerce.plus.plans.month')
    }`;
    perDurationFormatted =
      monthsFloored > 1
        ? t('core.ecommerce.plus.plans.for_months', { months })
        : t('core.ecommerce.plus.plans.month');
  } else {
    durationFormatted = `${subscriptionDuration} ${
      subscriptionDuration! > 1
        ? t('core.ecommerce.plus.plans.days')
        : t('core.ecommerce.plus.plans.day')
    }`;
    perDurationFormatted =
      subscriptionDuration! > 1
        ? t('core.ecommerce.plus.plans.for_days', {
            days: subscriptionDuration,
          })
        : t('core.ecommerce.plus.plans.day');
  }

  if (isFreeTrial || months < 1) {
    pricePerMonth = 0;
  } else {
    pricePerMonth = parseFloat((finalPrice / monthsFloored).toFixed(2));
  }

  return {
    durationFormatted,
    perDurationFormatted,
    pricePerMonth: (pricePerMonth / 100).toFixed(2),
  };
};
