import {
  AnalyticsContext,
  AnalyticsEvents,
  MappedProduct,
  MappedProductVariant,
  Product,
  ProductVariant,
} from '@swibeco/types';
import { getDiscountRate } from '../calculation';

const countryCodes = {
  fr: 'FR',
  en: 'US',
  de: 'DE',
  it: 'IT',
};

interface DataLayerObject {
  dataLayer: {
    event: AnalyticsEvents;
    environment: string;
    localisation?: string | null;
    ecommerce: {
      currency?: string;
      value?: number;
      type?: string;
      payment_type?: string;
      tax?: number;
      transaction_id?: number;
      items?: any;
    };
  };
}

type EcommerceOptions = Partial<{
  currency?: string;
  value?: number;
  tax?: number;
  transaction_id?: number;
  type?: string;
  payment_type?: string;
}>;

const isDefined = <T>(value: T | undefined): value is T => value !== undefined;

export const mapProductOrVariantToAnalyticsItem =
  (context: AnalyticsContext, locale: string) =>
  (item: MappedProductVariant | Product | ProductVariant) => {
    const mappedLocale = `${locale}_${countryCodes[locale]}`;

    if ('product' in item) {
      return {
        item_name: item.product.name,
        item_id: item.id,
        price: item.channelPricing?.price,
        item_brand: item.product.brand?.translations[mappedLocale].name,
        item_advantage: getDiscountRate(item),
        quantity: context.quantity,
        item_list_name: context.listName,
        item_list_id: context.listId,
        item_category: context.category,
      };
    }
    return {
      item_name: item.name,
      item_id: item.id,
      price: item.variantsMinimumPrice,
      item_brand: item.brand?.translations?.[mappedLocale]?.name,
      item_advantage: item.variantsMaximumDiscount,
      quantity: context.quantity,
      item_list_name: context.listName,
      item_list_id: context.listId,
      item_category: context.category,
    };
  };

export const createDataLayerProductVariantObject = (
  event: AnalyticsEvents,
  environment: string,
  ecommerceItems?: ((MappedProductVariant | MappedProduct) & {
    quantity?: number;
  })[],
  quantity?: number,
  localisation?: string | null,
  options: EcommerceOptions = {},
  ...rest: string[]
): DataLayerObject => {
  const items =
    ecommerceItems?.map((item) => {
      if (item === undefined) {
        throw new Error('item is undefined');
      }
      if ('product' in item) {
        return {
          item_name: item.product.name,
          item_id: item.id,
          price: item.channelPricing?.price,
          item_brand: item.product.brand?.translationsMap?.name,
          item_advantage: getDiscountRate(item),
          quantity: item.quantity || quantity || 1,
        };
      }
      return {
        item_name: item.name,
        item_id: item.id,
        price: item.variantsMinimumPrice,
        item_brand: item.brand?.translationsMap?.name,
        item_advantage: item.variantsMaximumDiscount,
        quantity: item.quantity || quantity || 1,
      };
    }) ?? [];

  const ecommerceObject = {
    currency: options.currency,
    value: options.value,
    type: options.type,
    payment_type: options.payment_type,
    tax: options.tax,
    transaction_id: options.transaction_id,
    items: items || [],
  };

  const ecommerceFiltered = Object.fromEntries(
    Object.entries(ecommerceObject).filter(([, value]) => isDefined(value))
  );

  const ecommerce = localisation
    ? { ...ecommerceFiltered, localisation }
    : ecommerceFiltered;

  const dataLayerObject: DataLayerObject = {
    dataLayer: {
      event,
      environment,
      ecommerce,
      ...rest,
    },
  };
  return dataLayerObject;
};
