import { API, camelcaseKeys } from '@swibeco/shared';
import {
  AddressesType,
  BasketResponse,
  UpdateUserBasketType,
} from '@swibeco/types';

const getUserAddresses = async (): Promise<AddressesType> => {
  const response = await API.get('/users/addresses');
  return camelcaseKeys(response.data, { deep: true });
};

const getUserBasket = async (locale?: string): Promise<BasketResponse> => {
  const response = await API.get('/user/basket', {
    params: {
      locale,
    },
  });
  return camelcaseKeys(response.data, { deep: true });
};

const updateUserBasket = async (
  basketUpdate: UpdateUserBasketType,
  locale: string
): Promise<BasketResponse> => {
  const response = await API.put(
    `/user/basket/?locale=${locale}`,
    basketUpdate
  );
  return camelcaseKeys(response.data, { deep: true });
};

const checkoutAPI = {
  getUserAddresses,
  getUserBasket,
  updateUserBasket,
};

export default checkoutAPI;
