import {
  BaseDeal,
  BasketItemRaw,
  BasketPageDeal,
  EnumDealOfferType,
  ProductPageDeal,
} from '@swibeco/types';

export const isProductBuyableWithSwipoints = (item: BasketItemRaw) =>
  item.variant.product.type === EnumDealOfferType.Physical ||
  item.variant.product.type === EnumDealOfferType.Voucher;

export const isBuyableWithSwipoints = (
  deal: BaseDeal | ProductPageDeal | BasketPageDeal
) =>
  deal.offerType === EnumDealOfferType.Physical ||
  deal.offerType === EnumDealOfferType.Voucher;

export const isPhysicalProduct = (deal: BaseDeal) =>
  deal.offerType === EnumDealOfferType.Physical;

export const isPromoCode = (deal: BaseDeal) =>
  deal.offerType === EnumDealOfferType.PromoCode;

export const isPromoLink = (deal: BaseDeal) =>
  deal.offerType === EnumDealOfferType.PromoLink;

export function isVoucher(deal: BaseDeal): boolean;
export function isVoucher(offerType: EnumDealOfferType): boolean;

export function isVoucher(param: BaseDeal | EnumDealOfferType) {
  if (typeof param === 'string') {
    return param === EnumDealOfferType.Voucher;
  }
  return param.offerType === EnumDealOfferType.Voucher;
}

export const isInternal = (deal: BaseDeal) => deal.isInternal;

export const hasCashback = (
  deal?: BaseDeal | ProductPageDeal | BasketPageDeal
): deal is {
  cashbackAmount: number;
  cashbackPercentage: number;
} & BaseDeal => {
  if (!deal) return false;
  return deal.cashbackAmount > 0 && deal.cashbackPercentage > 0;
};
export const isReimbursable = (deal: BaseDeal) => hasCashback(deal);

const dealValidator = {
  isPromoCode,
  isPromoLink,
  isPhysicalProduct,
  isVoucher,
  isBuyableWithSwipoints,
  isInternal,
  isReimbursable,
};
export default dealValidator;
