import { syliusApi } from '@swibeco/ecommerce';
import {
  AnalyticsEvents,
  ColorVariants,
  DealQuantityFormErrorType,
  EnumProductType,
  MappedProductVariant,
  Order,
  SyliusBasket,
} from '@swibeco/types';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import {
  actions,
  selectors as coreSelectors,
  SyliusErrorResponse,
  usePlatform,
} from '@swibeco/core';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { selectors as securitySelectors } from '@swibeco/security';
import TagManager from 'react-gtm-module';
import {
  CHECKOUT_CONFIRMATION,
  createDataLayerProductVariantObject,
  generatePageName,
  handleServerError,
} from '@swibeco/shared';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toast } from '@swibeco/ui';
import { useToast } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

export type MinimumRequiredProductVariant = Pick<MappedProductVariant, 'code'> &
  Pick<MappedProductVariant['product'], 'brand'> &
  Partial<MappedProductVariant> & { quantity: number };

export type AddItemType = UseMutateAsyncFunction<
  any,
  Error,
  {
    productVariants: MinimumRequiredProductVariant[];
    journey?: 'ecommerce' | 'subscription';
  }
>;

export type UpdateItemQuantityType = UseMutateAsyncFunction<
  any,
  Error,
  {
    itemCode: number;
    quantity: number;
  }
>;

export type BasketContextProps = {
  basket: SyliusBasket | undefined;
  addItem: AddItemType;
  updateItemQuantity: UpdateItemQuantityType;
  removeItem: UseMutateAsyncFunction<any, Error, number>;
  invalidateBasket: () => void;
  isLoading: boolean;
  isAddItemPending: boolean;
  isUpdateItemPending: boolean;
  hasPhysical: boolean | undefined;
  isBasketLoading: boolean;
  setJourney: React.Dispatch<
    React.SetStateAction<'ecommerce' | 'subscription'>
  >;
  journey: 'ecommerce' | 'subscription';
  hasSubscription: boolean | undefined;
  hasDigital: boolean | undefined;
  allocatedSwipoints: number;
  setAllocatedSwipoints: React.Dispatch<React.SetStateAction<number>>;
  finalAmountToPay: number;
  totalEconomy: number;
  createOrder: UseMutateAsyncFunction<any, Error, void>;
  getPayment: UseQueryResult<Order, Error>;
  tokenValue: string;
  setPaymentToken: Dispatch<SetStateAction<string | number | undefined>>;
};

const BasketContext = createContext({} as BasketContextProps);

export const BasketProvider = ({ children }: React.PropsWithChildren) => {
  const environment = usePlatform(window);
  const queryClient = useQueryClient();
  const locale = useSelector(coreSelectors.getLocale);
  const isDefaultLocale = useSelector(coreSelectors.getIsDefaultLocale);
  const isAuthenticated = useSelector(securitySelectors.isAuthenticated);
  const { pathname } = useLocation();
  const pageName = generatePageName(pathname);
  const dispatch = useDispatch();
  const [basket, setBasket] = React.useState<SyliusBasket | undefined>(
    undefined
  );
  const [journey, setJourney] = React.useState<'ecommerce' | 'subscription'>(
    'ecommerce'
  );
  const [allocatedSwipoints, setAllocatedSwipoints] = React.useState(0);
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();

  const { data, isLoading } = useQuery<SyliusBasket>({
    queryFn: () => syliusApi.getUserBasket(locale, journey),
    queryKey: ['sylius-basket', isAuthenticated, locale],
    refetchOnWindowFocus: false,
    retry: false,
    enabled: Boolean(isAuthenticated) && !isDefaultLocale,
  });

  useEffect(() => {
    if (data) {
      setBasket(data);
    }
  }, [data]);

  const tokenValue = data?.tokenValue || '';

  const handleMaxPerUserLimit = (error: SyliusErrorResponse) => {
    const { response } = error;
    if (response?.status === 422) {
      const codes = response.data.violations.map(
        (error: DealQuantityFormErrorType) => error.code
      );
      if (codes.includes('max_per_user_days_limit')) {
        const error = response.data.violations.find(
          (error: DealQuantityFormErrorType) =>
            error.code === 'max_per_user_days_limit'
        );
        const maxPerUser = error?.max_per_user;
        const maxPerUserDays = error?.max_per_user_days;
        toast({
          id: 'max-per-user-days-limit',
          position: 'top-right',
          isClosable: true,
          render: ({ onClose }) => (
            <Toast
              variant={ColorVariants.Danger}
              onCloseButtonClick={onClose}
              legacy={false}
            >
              <Trans
                i18nKey="core.ecommerce.universe.max_per_user_days_limit.error"
                values={{
                  maxPerUser,
                  maxPerUserDays,
                }}
              />
            </Toast>
          ),
        });
      }
      if (codes.includes('max_per_user_limit')) {
        toast({
          id: 'max-per-user-limit',
          position: 'top-right',
          isClosable: true,
          render: ({ onClose }) => (
            <Toast
              variant={ColorVariants.Danger}
              onCloseButtonClick={onClose}
              legacy={false}
            >
              {t('core.ecommerce.universe.max_per_user_limit.error')}
            </Toast>
          ),
        });
      }
      if (codes.includes('deal_quantity_reach')) {
        toast({
          id: 'out-of-stock',
          position: 'top-right',
          isClosable: true,
          render: ({ onClose }) => (
            <Toast
              variant={ColorVariants.Danger}
              onCloseButtonClick={onClose}
              legacy={false}
            >
              {t('core.ecommerce.universe.out_of_stock.error')}
            </Toast>
          ),
        });
      }
    } else {
      const serverError = handleServerError(error);
      toast({
        id: 'add-to-basket-error',
        position: 'top-right',
        isClosable: true,
        render: ({ onClose }) => (
          <Toast
            variant={ColorVariants.Danger}
            onCloseButtonClick={onClose}
            legacy={false}
            top="3.7rem"
            right={{
              base: '0',
              md: '1rem',
            }}
          >
            {serverError?.message}
          </Toast>
        ),
      });
    }
    return Promise.resolve();
  };

  const { mutateAsync: updateItemQuantity, isPending: isUpdateItemPending } =
    useMutation({
      mutationFn: ({
        itemCode,
        quantity,
      }: {
        itemCode: number;
        quantity: number;
      }) => {
        if (quantity === 0) {
          return syliusApi.removeProductFromCart(tokenValue, itemCode);
        }
        return syliusApi.updateCartQuantity(tokenValue, itemCode, quantity);
      },
      onSuccess: (data) => {
        setBasket(data);
        queryClient.invalidateQueries({
          queryKey: ['sylius-basket', isAuthenticated, locale],
        });
      },
      onError: (error: SyliusErrorResponse) => {
        handleMaxPerUserLimit(error);
      },
    });

  const { mutateAsync: addItem, isPending: isAddItemPending } = useMutation({
    mutationFn: ({
      productVariants,
      journey,
    }: {
      productVariants: MinimumRequiredProductVariant[];
      journey?: 'ecommerce' | 'subscription';
    }) => {
      TagManager.dataLayer(
        createDataLayerProductVariantObject(
          AnalyticsEvents.ADD_TO_CART,
          environment,
          productVariants as unknown as MappedProductVariant[],
          undefined,
          pageName
        )
      );
      return syliusApi.addToBasket(
        productVariants.map((productVariant) => ({
          productVariant: productVariant.code,
          quantity: productVariant.quantity,
        })),
        journey
      );
    },
    onSuccess: (data, { productVariants }) => {
      setBasket(data);
      queryClient.invalidateQueries({
        queryKey: ['sylius-basket', isAuthenticated, locale],
      });
      dispatch(
        actions.showOnAddItem(
          productVariants.reduce((acc, { quantity }) => acc + quantity, 0)
        )
      );
      setTimeout(() => {
        dispatch(actions.hideOnAddItem());
      }, 3000);
    },
    onError: (error: SyliusErrorResponse) => {
      handleMaxPerUserLimit(error);
    },
  });

  const { mutateAsync: removeItem } = useMutation({
    mutationFn: async (itemId: number) => {
      await syliusApi.removeProductFromCart(tokenValue, itemId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['sylius-basket', isAuthenticated, locale],
      });
    },
  });

  const { mutateAsync: createOrder } = useMutation({
    mutationFn: (data) => syliusApi.createOrder(data, tokenValue, journey),
    onSuccess: (data) => {
      if (data.payments.length > 0) {
        window.open(data.payments[0].redirectUrl, '_self');
      } else {
        navigate(`/${CHECKOUT_CONFIRMATION}?order_id=${data.tokenValue}`);
      }
    },
  });

  const [paymentToken, setPaymentToken] = useState<string | undefined>(
    undefined
  );

  const getPayment = useQuery({
    queryKey: ['sylius-payment', paymentToken],
    queryFn: () => syliusApi.checkPayment(paymentToken),
    enabled: Boolean(paymentToken),
    retry: true,
    retryDelay: 5000,
  });

  const invalidateBasket = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ['sylius-basket', isAuthenticated, locale],
    });
  }, [queryClient, isAuthenticated, locale]);

  useEffect(() => {
    if (getPayment.isSuccess) {
      invalidateBasket();
    }
  }, [getPayment.isSuccess, invalidateBasket]);

  const hasPhysical = basket?.items.some(
    (article) => article.variant.product.type === EnumProductType.DropShipping
  );
  const hasSubscription = basket?.items.some(
    (article) => article.variant.product.type === EnumProductType.Subscription
  );
  const hasDigital = basket?.items.some(
    (article) => article.variant.product.type === EnumProductType.Voucher
  );
  const finalAmountToPay = (basket?.total || 0) / 100 - allocatedSwipoints / 10;
  const totalEconomy =
    (basket?.items.reduce(
      (acc, cv) =>
        acc + cv.originalUnitPrice * cv.quantity - cv.unitPrice * cv.quantity,
      0
    ) || 0) / 100;

  const contextValue = useMemo(
    () => ({
      tokenValue,
      basket: data,
      isBasketLoading: isLoading,
      updateItemQuantity,
      addItem,
      removeItem,
      invalidateBasket,
      isLoading: isLoading || isAddItemPending || isUpdateItemPending,
      isAddItemPending,
      isUpdateItemPending,
      hasPhysical,
      journey,
      setJourney,
      hasSubscription,
      hasDigital,
      allocatedSwipoints,
      setAllocatedSwipoints,
      finalAmountToPay,
      totalEconomy,
      createOrder,
      getPayment,
      setPaymentToken,
    }),
    [
      data,
      tokenValue,
      updateItemQuantity,
      addItem,
      isLoading,
      removeItem,
      invalidateBasket,
      isAddItemPending,
      isUpdateItemPending,
      hasPhysical,
      journey,
      setJourney,
      hasSubscription,
      hasDigital,
      allocatedSwipoints,
      setAllocatedSwipoints,
      finalAmountToPay,
      totalEconomy,
      createOrder,
      getPayment,
      setPaymentToken,
    ]
  );

  return (
    <BasketContext.Provider value={contextValue}>
      {children}
    </BasketContext.Provider>
  );
};

export function useBasket() {
  const value = useContext(BasketContext);
  if (!value) {
    throw new Error('useBasket must be wrapped in a <BasketProvider />');
  }

  return value;
}
