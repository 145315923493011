import { LegacyAPI, API, camelcaseKeys } from '@swibeco/shared';
import {
  UserType,
  AuthenticationMethod,
  AuthenticationMethodType,
  CurrentCompanyInformationType,
  CompanyExcerptType,
  GetCurrentUserResponseType,
  GetCurrentCompanyResponseType,
  UserResetPasswordType,
} from '@swibeco/types';
import { parseISO } from 'date-fns';
import buildFeaturesForCompany, { AppFeatureAbility } from './features';

export type JWTResponseType = {
  data: {
    token: string;
  };
};

export default {
  refreshToken: async (): Promise<string> => {
    const response = await LegacyAPI.post<JWTResponseType>(
      '/auth/token/refresh',
      undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { skipAuthRefresh: true } as any
    );
    return response.data.data.token;
  },
  login: async (
    user: string,
    password: string,
    remember: boolean,
    company?: number
  ): Promise<string> => {
    const response = await LegacyAPI.post<JWTResponseType>('/auth/login', {
      email: user,
      password,
      company_id: company,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      remember,
    });
    return response.data.data.token;
  },
  loginByMfa: async (code: string): Promise<string> => {
    const response = await API.post<JWTResponseType>('/auth/login-by-mfa', {
      code,
    });
    return response.data.data.token;
  },
  sendCode: async () => {
    const response = await API.post('/auth/send-code');

    return response.data;
  },
  logout: async () => {
    await LegacyAPI.post('/auth/logout');
  },
  forgotPassword: async (email: string) => {
    await API.post('/users/forgot-password', {
      email,
    });
  },
  resetPassword: async (data: UserResetPasswordType) => {
    const fd = new FormData();

    fd.append('token', data.token);
    fd.append('plainPassword[first]', data.newPassword);
    fd.append('plainPassword[second]', data.confirmPassword);

    return LegacyAPI.post('/users/reset-password', fd, {
      headers: { 'content-type': 'multipart/form-data' },
    });
  },
  requestInvitation: async (email: string) => {
    await API.post('/users/request-invitation', {
      email,
    });
  },
  listSSOCompanies: async () => {
    const response = await API.get<CompanyExcerptType[]>(
      '/security/list-sso-companies'
    );
    return response.data;
  },
  switchSSOUser: async (newCompanyId: number) => {
    await API.post('/security/switch-sso-user', { company: newCompanyId });
  },
  /**
   * Returns the current user
   * @returns {UserType}
   */
  getCurrentUser: async (): Promise<UserType> => {
    const { data } = await API.get<GetCurrentUserResponseType>('/users/me');

    const user = {
      ...data,
      kind: 'CurrentUser',
      birth_date: data.birth_date ? parseISO(data.birth_date) : null,
      entry_date: data.entry_date ? parseISO(data.entry_date) : null,
      end_date: data.end_date ? parseISO(data.end_date) : null,
    };

    const cc = camelcaseKeys(user, {
      deep: true,
      stopPaths: [
        'permissions',
        'roles',
        'birth_date',
        'end_date',
        'entry_date',
      ],
    });
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    return cc;
  },
  updateDashboardTutorialFlag: async () => {
    await API.patch('/users/dashboard-tutorial');
  },
  /**
   * Returns the current company
   * @returns {CurrentCompanyInformationType & {ability?: AppFeatureAbility}}
   */
  getCurrentCompany: async (): Promise<
    CurrentCompanyInformationType & { ability?: AppFeatureAbility }
  > => {
    const { data } = await API.get<GetCurrentCompanyResponseType>(
      '/company/current'
    );

    const currentCompanyInformation: CurrentCompanyInformationType = {
      ...data,
      kind: 'CurrentCompany',
      authentication_methods: data.authentication_methods.map(
        (method: AuthenticationMethod) => ({
          ...method,
          type: AuthenticationMethodType.SSO, // TODO: implement other methods here
        })
      ),
      subdomain_belongs_to_company: !!data.theme,
      theme: {
        main_color: data.theme?.main_color || '#006CFF',
        text_color: data.theme?.text_color || '#1B2A6A',
        logo:
          // eslint-disable-next-line operator-linebreak
          data.theme?.logo ||
          `${process.env.PUBLIC_URL}/static/img/placeholders/swibeco_logo.png`,
        cover:
          // eslint-disable-next-line operator-linebreak
          data.theme?.cover ||
          `${process.env.PUBLIC_URL}/static/img/placeholders/swibeco_cover.png`,
        favicon: {
          path:
            // eslint-disable-next-line operator-linebreak
            data.theme?.favicon?.path ||
            `${process.env.PUBLIC_URL}/static/img/placeholders/swibeco_favicon.png`,
          mime_type: data.theme?.favicon?.mime_type || 'image/png',
        },
        partner_logo: data.theme?.partner_logo || null,
        dealer_logo: data.theme?.dealer_logo || null,
      },
      can_manage_users: data.can_manage_users || false,
      contract_start_date: data.contract_start_date
        ? parseISO(data.contract_start_date)
        : null,
      dealer_is_axa: data.dealer_is_axa,
      name: data.name,
    };

    if (currentCompanyInformation.authentication_methods.length) {
      // eslint-disable-next-line operator-linebreak
      currentCompanyInformation.has_sso =
        currentCompanyInformation.authentication_methods.some(
          (authenticationMethod) =>
            authenticationMethod.type === AuthenticationMethodType.SSO
        );
    }

    return {
      ...currentCompanyInformation,
      ...(currentCompanyInformation.features && {
        ability: buildFeaturesForCompany(currentCompanyInformation),
      }),
    };
  },
};
