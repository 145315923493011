export type UserOrdersType = {
  price: number;
  name: string;
  date: string;
  pdf: string;
  vouchers_v2: boolean;
  vouchers: VoucherItemType[];
};

type Voucher = {
  id: number;
  voucher_code_type: VoucherTypeType;
  v2: boolean;
};

export type VoucherItemType = Voucher & {
  deal: VoucherDealType;
};

export type VoucherDealType = {
  title: string;
  list_price_vat: number;
  final_price_vat: number;
  discount: number;
  thumbnail_image?: string;
  gallery_image?: string;
  brand_logo?: string;
  brand_name?: string;
};

export type VoucherProps = {
  voucher: UserOrdersType;
  className?: string;
};

export type VoucherInfoType = {
  id: number;
  voucher_code_type?: VoucherTypeType;
  code?: string;
  url?: string;
  valid_until?: string;
};

export enum VoucherTypeType {
  Code = "code",
  Url = "url",
  File = "file",
}

// Invoices
export interface InvoicesResultType {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  next_page_url: string;
  prev_page_url: string;
  from: number;
  to: number;
  data: InvoiceType[];
  query: string | undefined;
}

export type InvoiceType = {
  id: number;
  ref: number;
  created_at: string;
  vouchers_v2: boolean;
  invoice_items: InvoiceItemType[];
  currency: string;
  currency_symbol: string;
  ati_total_sub: number;
  ati_total_delivery: number;
  ati_total_final: number;
  pdf: string;
  voucherpdf: string | undefined;
  payment_method: string;
  payment_method_str: string;
  delivery: Billing | undefined;
  billing: Billing | undefined;
};

type InvoiceItemType = {
  currency: string;
  currency_symbol: string;
  quantity: number;
  title: string;
  deal_id: number;
  list_price: number;
  list_price_vat: number;
  final_price: number;
  vat_rate: number;
  delivery_price: number;
  delivery_vat_rate: number;
  thumbnail_image: string;
  gallery_image?: string;
  brand_logo: string;
  type: string;
  vouchers: Voucher[];
};

export type Billing = {
  address1: string;
  address2: string;
  zip: string;
  city: string;
  country: string;
  phone_prefix: string;
  phone: string;
  firstname: string;
  lastname: string;
};
