import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type * as rtk from '@reduxjs/toolkit';

export interface CoreState {
  locale: string;
  isDefaultLocale: boolean;
  logo: string;
  dealerLogo: null | string;
  footer: {
    visible: boolean;
  };
  displayLoginLogo: boolean;
  menu: {
    visible: boolean;
  };
  header: {
    full: boolean;
    topnavbar: boolean;
  };
  sidebar: {
    visible: boolean;
  };
  dealer: {
    dealer_is_axa: boolean;
  };
  basketFeedback: {
    itemAdded: {
      visible: boolean;
      quantity: number;
    };
    outOfStock: {
      visible: boolean;
    };
  };
  favouritesFeedbackToaster: {
    visible: boolean;
    itemAdded: {
      visible: boolean;
    };
  };
  swipointsLocation: {
    x: number;
    y: number;
  };
  christmasFlag: boolean;
}

const initialState: CoreState = {
  locale: 'fr',
  isDefaultLocale: true,
  logo: `${process.env.PUBLIC_URL}/static/img/placeholders/swibeco_logo.png`,
  dealerLogo: null,
  footer: {
    visible: true,
  },
  displayLoginLogo: true,
  menu: {
    visible: false,
  },
  header: {
    full: true,
    topnavbar: true,
  },
  sidebar: {
    visible: false,
  },
  dealer: {
    dealer_is_axa: false,
  },
  basketFeedback: {
    itemAdded: {
      visible: false,
      quantity: 1,
    },
    outOfStock: {
      visible: false,
    },
  },
  favouritesFeedbackToaster: {
    visible: false,
    itemAdded: {
      visible: false,
    },
  },
  swipointsLocation: {
    x: 0,
    y: 0,
  },
  christmasFlag: false,
};

const coreSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    reset: () => initialState,
    hideFooter: (state) => {
      state.footer.visible = false;
    },
    showFooter: (state) => {
      state.footer.visible = true;
    },
    showOnAddItem: (state, action: PayloadAction<number>) => {
      state.basketFeedback.itemAdded.visible = true;
      state.basketFeedback.itemAdded.quantity = action.payload;
    },
    hideOnAddItem: (state) => {
      state.basketFeedback.itemAdded.visible = false;
    },
    showOutOfStock: (state) => {
      state.basketFeedback.outOfStock.visible = true;
    },
    hideOutOfStock: (state) => {
      state.basketFeedback.outOfStock.visible = false;
    },
    // addItemFavourites: (state) => {
    //   state.favouritesFeedbackToaster.itemAdded.visible = true;
    // },
    // removeItemFavourites: (state) => {
    //   state.favouritesFeedbackToaster.itemAdded.visible = false;
    // },
    showFavouritesFeedbackToaster: (state) => {
      state.favouritesFeedbackToaster.visible = true;
    },
    hideFavouritesFeedbackToaster: (state) => {
      state.favouritesFeedbackToaster.visible = false;
    },
    toggleMenu: (state) => {
      state.menu.visible = !state.menu.visible;
    },
    showMenu: (state) => {
      state.menu.visible = true;
    },
    hideMenu: (state) => {
      state.menu.visible = false;
    },
    showFullHeader: (state) => {
      state.header.full = true;
    },
    hideFullHeader: (state) => {
      state.header.full = false;
    },
    showSidebar: (state) => {
      state.sidebar.visible = true;
    },
    hideSidebar: (state) => {
      state.sidebar.visible = false;
    },
    setLogo: (state, action: PayloadAction<string>) => {
      state.logo = action.payload;
    },
    setDealerLogo: (state, action: PayloadAction<string>) => {
      state.dealerLogo = action.payload;
    },

    setLoginLogoVisibility: (state, action: PayloadAction<boolean>) => {
      state.displayLoginLogo = action.payload;
    },
    setLocale: (state, action: PayloadAction<string>) => {
      state.locale = action.payload;
      state.isDefaultLocale = false;
    },
    setDealerInfo: (state, action: PayloadAction<boolean>) => {
      state.dealer.dealer_is_axa = action.payload;
    },
    setBasicStructure: (
      state,
      action: PayloadAction<{ topnavbar: boolean } | undefined>
    ) => {
      state.footer.visible = false;
      state.header.full = false;
      state.header.topnavbar =
        typeof action.payload?.topnavbar === 'boolean'
          ? action.payload.topnavbar
          : false;
    },
    setFullStructure: (
      state,
      action: PayloadAction<{ topnavbar: boolean } | undefined>
    ) => {
      state.footer.visible = true;
      state.header.full = true;
      state.header.topnavbar =
        typeof action.payload?.topnavbar === 'boolean'
          ? action.payload.topnavbar
          : true;
    },
    setSwipointsIconLocation: (
      state,
      action: PayloadAction<{ x: number; y: number }>
    ) => {
      state.swipointsLocation = action.payload;
    },
  },
});

const getLogo = (state: { core: CoreState }): string => state.core.logo;
const getDealerLogo = (state: { core: CoreState }): string | null =>
  state.core.dealerLogo;
const isLoginLogoVisible = (state: { core: CoreState }): boolean =>
  state.core.displayLoginLogo;
const getLocale = (state: { core: CoreState }): string => state.core.locale;
const getIsDefaultLocale = (state: { core: CoreState }): boolean =>
  state.core.isDefaultLocale;
const isFooterVisible = (state: { core: CoreState }): boolean =>
  state.core.footer.visible;
const isBasketFeedbackOnAddItemVisible = (state: {
  core: CoreState;
}): boolean => state.core.basketFeedback.itemAdded.visible;
const isBasketFeedbackOutOfStockVisible = (state: {
  core: CoreState;
}): boolean => state.core.basketFeedback.outOfStock.visible;
const isFavouritesItemAddedVisible = (state: { core: CoreState }): boolean =>
  state.core.favouritesFeedbackToaster.itemAdded.visible;

const basketQuantityAdded = (state: { core: CoreState }): number =>
  state.core.basketFeedback.itemAdded.quantity;

const isFavouritesFeedbackToasterVisible = (state: {
  core: CoreState;
}): boolean => state.core.favouritesFeedbackToaster.visible;

const isMenuVisible = (state: { core: CoreState }): boolean =>
  state.core.menu.visible;
const isHeaderFull = (state: { core: CoreState }): boolean =>
  state.core.header.full;
const isSidebarVisible = (state: { core: CoreState }): boolean =>
  state.core.sidebar.visible;
const isDealerAxa = (state: { core: CoreState }): boolean =>
  state.core.dealer.dealer_is_axa;
const hasTopNavbar = (state: { core: CoreState }): boolean =>
  state.core.header.topnavbar;
const getSwipointsLocation = (state: {
  core: CoreState;
}): { x: number; y: number } => state.core.swipointsLocation;
const getChristmasFlag = (state: { core: CoreState }): boolean =>
  state.core.christmasFlag;

export const actions = { ...coreSlice.actions };
export const selectors = {
  getLogo,
  getDealerLogo,
  getLocale,
  isMenuVisible,
  isHeaderFull,
  isFooterVisible,
  isLoginLogoVisible,
  isSidebarVisible,
  isDealerAxa,
  hasTopNavbar,
  isBasketFeedbackOnAddItemVisible,
  isBasketFeedbackOutOfStockVisible,
  isFavouritesItemAddedVisible,
  isFavouritesFeedbackToasterVisible,
  basketQuantityAdded,
  getSwipointsLocation,
  getIsDefaultLocale,
  getChristmasFlag,
};
export const { reducer } = coreSlice;
