import { Flex, Box, Image, useEnvironment } from '@chakra-ui/react';
import {
  AnalyticsEvents,
  EnumLinkProps,
  PartnerOffer as PartnerOfferType,
} from '@swibeco/types';
import React, { useEffect, useMemo } from 'react';
import { useLanguageSwitcher } from '@swibeco/core';
import { AnimatePresence, motion } from 'framer-motion';
import { Button, HoverBehavior, Legals, Link, Text } from '@swibeco/ui';
import { useQuery } from '@tanstack/react-query';
import { ECOMMERCE_PRODUCT_ROOT, wpAPI } from '@swibeco/shared';
import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import { BottomSheet } from '../../components/BottomSheet';
import { ReactComponent as HourglassIcon } from '../../assets/images/hourglass.svg';
import { ReactComponent as IconCross } from '../../assets/images/cross.svg';

const PartnerOffer = ({
  offer,
  expired,
  closeBottomSheet,
}: {
  offer: PartnerOfferType;
  expired?: boolean;
  closeBottomSheet?: () => void;
}) => {
  const environment = useEnvironment;
  const { t } = useTranslation();
  const { currentLocale } = useLanguageSwitcher();
  const date = parseISO(offer.promotion_end_date);
  const navigate = useNavigate();
  const handleLinkClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: AnalyticsEvents.SELECT_ITEM,
        environment,
        link: offer.promotion_url,
      },
    });
  };
  const handleButtonClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: AnalyticsEvents.SELECT_ITEM,
        environment,
        slug: offer.slug,
      },
    });
    navigate(`${ECOMMERCE_PRODUCT_ROOT}/${offer.slug}`);
    closeBottomSheet?.();
  };

  return (
    <Box
      p="16px"
      border="1px solid"
      borderColor="default.main"
      bgColor={expired ? 'default.main' : 'white'}
    >
      <Flex gap="16px" flexDirection="column">
        <Flex gap="16px">
          <Image
            borderRadius="4px"
            borderColor="default.main"
            borderWidth="1px"
            borderStyle="solid"
            objectFit="contain"
            h="74px"
            w="74px"
            src={offer.brand_image}
            alt="AXA"
            width="80px"
          />
          <Flex flexDir="column" gap="8px">
            {offer.promotion_end_date && (
              <Flex>
                <HourglassIcon />
                <Legals color="complementary.red">
                  {expired
                    ? t('core.ecommerce.partner_offers.end.expired')
                    : t('core.ecommerce.partner_offers.end')}{' '}
                  {format(date, 'dd MMM')}
                </Legals>
              </Flex>
            )}
            <Text bold>{offer[`${currentLocale}_title`]}</Text>
            <Text>{offer[`${currentLocale}_description`]}</Text>
          </Flex>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gap="16px"
        >
          <Button
            color="primary"
            style={{
              paddingLeft: '16px',
              paddingRight: '16px',
              paddingTop: '12px',
              paddingBottom: '12px',
            }}
            disabled={expired}
            onClick={handleButtonClick}
          >
            {t('core.ecommerce.partner_offers.button')}
          </Button>
          {!!offer.promotion_url && offer.promotion_url !== '' && (
            <Box color={expired ? 'default.strong' : 'primary.main'}>
              <HoverBehavior disabled={expired}>
                <Link
                  style={{
                    color: 'inherit',
                  }}
                  onClick={handleLinkClick}
                  target="_blank"
                  rel="noopener noreferrer"
                  to={offer.promotion_url}
                  type={EnumLinkProps.SamePage}
                >
                  {t('core.ecommerce.partner_offers.link')}
                </Link>
              </HoverBehavior>
            </Box>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

const SidePanel = ({ show, children, ...rest }: any) => (
  <AnimatePresence>
    {show && (
      <motion.div
        key="panel-animation"
        transition={{
          duration: 0.7,
        }}
        initial={{ x: '100vw' }}
        animate={{ x: show ? 0 : '100vw' }}
        exit={{
          x: '100vw',
          transition: {
            duration: 2,
          },
        }}
        style={{
          borderRight: '1px solid',
          position: 'fixed',
          right: 0,
          height: '100%',
          width: '400px',
          zIndex: 9999,
          top: 0,
          display: 'block',
        }}
        {...rest}
      >
        {children}
      </motion.div>
    )}
  </AnimatePresence>
);

const PanelContent = ({
  partnerOffers,
  closeBottomSheet,
}: {
  partnerOffers: PartnerOfferType[];
  closeBottomSheet: () => void;
}) => {
  const { t } = useTranslation();
  const validOffers = useMemo(
    () =>
      partnerOffers.filter((offer) =>
        offer.promotion_end_date
          ? new Date(offer.promotion_end_date) >= new Date()
          : true
      ),
    [partnerOffers]
  );

  const expiredOffers = useMemo(
    () =>
      partnerOffers.filter(
        (offer) =>
          offer.promotion_end_date &&
          new Date(offer.promotion_end_date) < new Date()
      ),
    [partnerOffers]
  );

  return (
    <Flex
      direction="column"
      gap="8px"
      p="28px"
      h="100%"
      pb="32px"
      bgColor="default.backgroundColor"
      pos="relative"
    >
      {validOffers.length > 0 && (
        <Flex direction="column" gap="8px" pb="32px">
          <Text important>{t('core.ecommerce.partner_offers.title')}</Text>
          <Text>{t('core.ecommerce.partner_offers.description')}</Text>
          <Box
            top="41px"
            right="24px"
            pos="absolute"
            onClick={closeBottomSheet}
            w="13.4px"
            h="13.4px"
            cursor="pointer"
            data-testid="header-userprofile-close"
          >
            <IconCross />
          </Box>
          {validOffers.map((item) => (
            <PartnerOffer
              key={item.id}
              offer={item}
              closeBottomSheet={closeBottomSheet}
            />
          ))}
        </Flex>
      )}

      {expiredOffers.length > 0 && (
        <Flex gap="8px" flexDirection="column" pb="32px">
          <Text important>
            {t('core.ecommerce.partner_offers.title.expired')}
          </Text>
          {expiredOffers.map((item) => (
            <PartnerOffer key={item.id} offer={item} expired />
          ))}
        </Flex>
      )}
    </Flex>
  );
};

type UserProfilePanel = {
  isOpen: false | 'mobile' | 'desktop';
  setIsOpen: (isOpen: false | 'mobile' | 'desktop') => void;
};

const PartnerOffersPanel = ({ isOpen, setIsOpen }: UserProfilePanel) => {
  const closeBottomSheet = () => {
    setIsOpen(false);
  };
  const { data: response, refetch } = useQuery<{
    data: { acf: PartnerOfferType; id: string }[];
  }>({
    queryKey: ['partnerOffers'],
    queryFn: () =>
      wpAPI.get('/partner-promotion', {
        params: {
          per_page: 50,
        },
      }),
    staleTime: 0,
    refetchOnWindowFocus: true,
    refetchInterval: 5 * 60 * 1000, // 5 minutes
    refetchOnMount: true,
  });
  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen, refetch]);
  const partnerOffers =
    response?.data?.map((item) => ({
      ...item.acf,
      id: item.id,
    })) || [];

  return (
    <>
      <BottomSheet
        panelKey="partner-offers"
        show={isOpen === 'mobile'}
        buttonDestroy={false}
        onDidDismiss={() => {
          closeBottomSheet();
        }}
      >
        <PanelContent
          partnerOffers={partnerOffers}
          closeBottomSheet={closeBottomSheet}
        />
      </BottomSheet>
      <AnimatePresence>
        {isOpen === 'desktop' && (
          <motion.div
            key="panel-animation"
            data-testid="outside-panel"
            transition={{
              duration: 0.7,
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{
              opacity: 0,
              transition: {
                duration: 1,
              },
            }}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
              display: isOpen ? 'block' : 'none',
              justifyContent: 'start',
              alignItems: 'stretch',
              zIndex: 9999,
              overflow: 'none',
            }}
            onClick={() => {
              closeBottomSheet();
            }}
          />
        )}
      </AnimatePresence>
      <SidePanel data-testid="panel" show={isOpen === 'desktop'}>
        <Box
          style={{
            overflow: 'auto',
            width: '400px',
            height: '100%',
            backgroundColor: 'white',
            position: 'absolute',
            right: 0,
          }}
        >
          <PanelContent
            partnerOffers={partnerOffers}
            closeBottomSheet={closeBottomSheet}
          />
        </Box>
      </SidePanel>
    </>
  );
};
export default PartnerOffersPanel;
