import React, { useMemo, useRef } from 'react';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { selectors as coreSelectors, useIsTablet } from '@swibeco/core';
import { BASKET_PAGE, useTheme } from '@swibeco/shared';
import { ColorVariants } from '@swibeco/types';
import { Link, Popover, Text, Toast } from '@swibeco/ui';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, useDisclosure } from '@chakra-ui/react';
import { BasketProvider, useBasket } from '../../contexts';
import { Badge } from './Badge';
import * as Styles from './styles/MyBasket.styles';
import { ReactComponent as EmptyBasketIcon } from '../../assets/images/empty-basket-icon.svg';
import { ReactComponent as SantaBag } from '../../assets/images/santa-bag.svg';
import { ReactComponent as ChristmasEmptyBasketIcon } from '../../assets/images/christmas-empty-basket.svg';

const MyBasket = () => {
  const { t } = useTranslation();
  const myBasketrRef = useRef(null);
  const isTablet = useIsTablet();
  const theme = useTheme();
  const isBasketFeedbackVisible = useSelector(
    coreSelectors.isBasketFeedbackOnAddItemVisible
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const christmasFlag = useSelector(coreSelectors.getChristmasFlag);

  const basketQuantityAdded = useSelector(coreSelectors.basketQuantityAdded);

  const { basket } = useBasket();

  const handleControlledOpen = () => {
    if (!isTablet && basket?.items.length === 0) {
      onOpen();
    }
  };

  const basketItemsCount = useMemo(
    () => basket?.items.reduce((acc, item) => acc + item.quantity, 0),
    [basket]
  );

  return (
    <>
      <Styles.HeaderItemMyBasket>
        <Popover
          onOpen={handleControlledOpen}
          onClose={onClose}
          isOpen={isOpen}
          popoverTrigger={
            <Link
              to={BASKET_PAGE}
              data-testid="mybasket-btn"
              id="mybasket-icon"
              ref={myBasketrRef}
            >
              <Badge count={basketItemsCount}>
                {christmasFlag ? (
                  <SantaBag
                    size="lg"
                    style={{
                      transform: 'translate(-15%, 5%)',
                    }}
                    color={theme.colors.default.black}
                  />
                ) : (
                  <Styles.MyBasketIcon
                    icon={faShoppingCart}
                    size="lg"
                    color={theme.colors.default.black}
                  />
                )}

                <span className="background" />
              </Badge>
            </Link>
          }
        >
          <Box
            p={4}
            display="flex"
            alignItems="center"
            flexDir="column"
            justifyContent="center"
          >
            {christmasFlag ? <ChristmasEmptyBasketIcon /> : <EmptyBasketIcon />}
            <Text color="primary.dark" fontWeight="bold" className="mt-1">
              {christmasFlag
                ? t('core.basket.product.empty_basket.christmas')
                : t('core.basket.product.empty_basket')}
            </Text>
          </Box>
        </Popover>
      </Styles.HeaderItemMyBasket>
      <Styles.ToastWrapper
        visible={isBasketFeedbackVisible}
        data-testid="add-to-cart-feedback"
      >
        <Toast
          variant={ColorVariants.Success}
          isOpen={isBasketFeedbackVisible}
          bordered={false}
          footer={
            <Link
              to={BASKET_PAGE}
              className="w-100 d-flex align-items-center text-decoration-none"
            >
              <Styles.SeeMyBasket color="primary" thin>
                {t('core.basket.see.my.basket')}
              </Styles.SeeMyBasket>
            </Link>
          }
        >
          <Text color="complementary.light" fontWeight="bold">
            {basketQuantityAdded > 1
              ? `${basketQuantityAdded} ${t(
                  'core.basket.products.added.successfully'
                )}`
              : `${basketQuantityAdded} ${t(
                  'core.basket.product.added.successfully'
                )}`}
          </Text>
        </Toast>
      </Styles.ToastWrapper>
    </>
  );
};

const WrappedMyBasket = () => (
  <BasketProvider>
    <MyBasket />
  </BasketProvider>
);

export default WrappedMyBasket;
