import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { LOGIN, SECURITY } from './router';

export type { AxiosError, AxiosRequestConfig } from 'axios';

export const JWT_STORAGE: { token: string | null } = {
  token: null,
};

const ROOT_URL = '';
// TEST URLs - Uncomment one of the below URLs to connect to the respective server in dev mode
// const ROOT_URL = "https://swibeco.stg.premiumemployee.ch"
// const ROOT_URL = 'https://swibeco.gamma.premiumemployee.ch'
// const ROOT_URL = 'https://swibeco.goat.premiumemployee.ch'
// const ROOT_URL = 'https://swibeco.test.premiumemployee.ch'
// const ROOT_URL = 'https://swibeco.preprod.premiumemployee.ch'
// TEST URLs

export const LegacyAPI = axios.create({
  baseURL: `${ROOT_URL}/api/v1`,
});

export const API = axios.create({
  baseURL: `${ROOT_URL}/api/v2`,
});

export const SyliusAPI = axios.create({
  baseURL: `${ROOT_URL}/api/v3`,
});

export const wpAPI = axios.create({
  baseURL: 'https://www.swibeco.ch/wp-json/wp/v2',
});

const interceptor = (config: InternalAxiosRequestConfig) => {
  // eslint-disable-next-line no-param-reassign
  config.withCredentials = true;
  if (JWT_STORAGE.token) {
    /**
     * Non-nullable assertion because Axios will always implement headers
     */
    // eslint-disable-next-line no-param-reassign
    config.headers!.Authorization = `Bearer ${JWT_STORAGE.token}`;
  }
  return config;
};

const onResponse = (response: AxiosResponse) => response;

const refreshToken = async (): Promise<string> => {
  try {
    const response = await LegacyAPI.post(
      '/auth/token/refresh',
      undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { skipAuthRefresh: true } as any
    );
    return response.data.data.token;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      window.location.assign(`/v2/${SECURITY}/${LOGIN}`);
    }
    return Promise.reject(error);
  }
};

/* istanbul ignore next */

const errorInterceptor = (e: any) => Promise.reject(e);
const onErrorResponse = async (error: AxiosError) => {
  let res;
  const originalRequest = error.config;

  // handle unauthenticated requests by trying a refresh
  if (error.response && error.response.status === 401) {
    let jwt;
    try {
      jwt = await refreshToken();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        window.location.assign(`/v2/${SECURITY}/${LOGIN}`);
        return Promise.reject(error);
      }
    }
    // is we can't find a refresh token we error early;
    if (!jwt) {
      return Promise.reject(error);
    }
    JWT_STORAGE.token = jwt;
    if (originalRequest) {
      res = await API.request(originalRequest);
      return Promise.resolve(res);
    }
  }

  // other error cases that we do not handle here (404, 403, etc)
  return Promise.reject(error);
};

API.interceptors.request.use(interceptor, errorInterceptor);
LegacyAPI.interceptors.request.use(interceptor, errorInterceptor);
SyliusAPI.interceptors.request.use(interceptor, errorInterceptor);
API.interceptors.response.use(onResponse, onErrorResponse);
