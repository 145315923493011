/* eslint-disable @typescript-eslint/no-explicit-any */
import otaClient from '@crowdin/ota-client';

type StringsByLocaleResult = { [key: string]: string }; // Example type, adjust as needed

export class CrowdinOtaI18next {
  type: string;

  otaClient: any;

  constructor(hash: string) {
    this.type = 'backend';
    // eslint-disable-next-line new-cap
    this.otaClient = new otaClient(hash);
  }

  read(
    language: string,
    _namespace: string,
    callback: (
      error: Error | null,
      result: StringsByLocaleResult | null
    ) => void
  ) {
    this.otaClient
      .getStringsByLocale(language)
      .then((value: StringsByLocaleResult) => callback(null, value))
      .catch((e: Error) => callback(e, null));
  }
}
